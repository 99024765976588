@import "../../SCSS/CommonSCSS/mixins.scss";

// Proposal Footer
#{$proposal}FooterMain {
  background: $white;
  @include position(fixed, $bottom: 0px, $left: 0px);
  height: 76px;
  width: 100%;
  // max-width: 1000px;
  z-index: 9;
  padding-top: 24px;

  &:before {
    background: rgba($black, 0.1);
    @include position(absolute, $top: 0px, $right: 0px);
    content: "";
    height: 1px;
    width: 75%;
    // max-width: 1000px;
  }

  .footerInner {
    // min-height: 84px;
    display: flex;
    align-items: center;
    // padding-left: 324px;
    // position: sticky;
    justify-content: center;
  }

  .backStep {
    position: relative;
    color: $black;
    padding: 4px 0px;
    display: inline-flex;
    align-items: center;
    min-width: 132px;
    text-decoration: none;
    font-size: 14px;

    &:hover {
      cursor: pointer;
      color: $theme-green;

      svg {
        left: -4px;
      }
    }

    svg {
      height: 16px;
      position: relative;
      left: 0px;
      transition: all 0.3s ease;
    }
  }

  .ctaBtn {
    flex: 0 0 100%;
    max-width: 72%;
    text-align: center;
  }

  &.paymentLinkPage {
    .ctaBtn {
      flex: none !important;
      max-width: none !important;
    }
  }
}

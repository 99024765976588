@import "../../SCSS/CommonSCSS/mixins.scss";

.insuredMembers {
  display: block;
  // height: 170px;

  .adult {
    &:before {
      background: url("../../../public/images/adult-icon.svg") no-repeat 0px 0px/100%;
    }
  }
  .child {
    &:before {
      background: url("../../../public/images/child-icon.svg") no-repeat 0px 0px/100%;
    }
  }

  // $infoicons: father, mother, self, spouse, son, daughter, childSon,
  //   childDaughter, others;
  // $k: 0;

  // @each $icons in $infoicons {
  //   $bacRightP: $k * 120;
  //   $k: $k + 1;

  //   .#{$icons} {
  //     &:before {
  //       background: url("../../../public/images/policy-members.svg") no-repeat -#{$bacRightP}px 0px/1080px;
  //     }
  //   }
  // }

  input {
    display: none;
  }

  label {
    position: relative;
    @include fontSize(13px);
    color: #000;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
    line-height: 16px;
    margin: 0 auto;
    width: 120px;
    display: block;
    z-index: 1;

    &:before {
      content: "";
      -webkit-appearance: none;
      background-color: transparent;
      border: 1px solid rgba($black, 0.2);
      @include border-radius(4px);
      height: 112px;
      width: 120px;
      display: block;
      position: relative;
      margin-bottom: 8px;
      z-index: -1;
    }
  }

  input:checked + label {
    &:before {
      background-color: $theme-blue;
    }

    &::after {
      @include position(absolute, $top: 5px, $right: 10px);
      content: "";
      border: solid $white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      height: 12px;
      width: 6px;
      z-index: 999;
    }
  }
}

@import "../../../SCSS/CommonSCSS/mixins.scss";

.mAddonQuestion {
  width: 100%;

  &.borderBottom {
    position: relative;

    &:before {
      border-bottom: 1px solid rgba($black, 0.1);
      @include position(absolute, $bottom: 0px, $left: 12px);
      content: "";
      height: 1px;
      width: calc(100% - 24px);
      text-align: center;
    }
  }

  .amount {
    color: $secondary;
    @include fontSize(16px);
    font-weight: 500;
  }

  .innerheading {
    font-family: "poppins", sans-serif;
    font-weight: 500;
    font-size: 14px;
    display: inline-block;
    position: relative;
    color: $primary;

    &:before {
      @include position(absolute, $left: 0, $bottom: -6px);
      content: "";
      background: $primary;
      height: 1px;
      width: 100%;
    }
  }

  .mAddonQuestionInner {
    display: flex;
    align-items: center;

    h6 {
      font-family: "poppins", sans-serif;
      font-weight: 500;
      // min-height: 40px;
      display: flex;
      align-items: center;
    }

    p {
      margin-top: 8px;
      margin-bottom: 0px;
      color: rgba($black, 0.6);
      @include fontSize(13px);
    }

    ul {
      margin-left: 12px;

      li {
        color: rgba($black, 0.6);
        @include fontSize(13px);
      }
    }

    $producticons: amount, bonus, hospital, healthcheckups, accident, doc,
      extrapremium, location, treatments, recharge, searched, familys, discount;
    $k: 0;

    @each $icons in $producticons {
      // $bacRightP: $k * 50;
      $k: $k + 1;

      .#{$icons} {
        background: url("../../../../public/images/q-icon.svg") no-repeat 0px 0px/100%;
        // background: url("../../../public/images/addon-icons.svg")
        //   no-repeat -#{$bacRightP}px
        //   0px/650px;
        display: block;
        margin-right: 12px;
        height: 36px;
        min-width: 36px;
        width: 36px;
      }
    }

    // $producticons: amount, bonus, hospital, healthcheckups, accident, doc,
    //   extrapremium, location, treatments, recharge, searched, familys, discount;
    // $k: 0;

    // @each $icons in $producticons {
    //   $bacRightP: $k * 50;
    //   $k: $k + 1;

    //   .#{$icons} {
    //     background: url("../../../../public/images/addon-icons.svg")
    //       no-repeat -#{$bacRightP}px
    //       0px/650px;
    //     display: block;
    //     margin-right: 12px;
    //     height: 50px;
    //     min-width: 50px;
    //     width: 50px;
    //   }
    // }
  }

  .question-part {
    h6 {
      font-family: "poppins", sans-serif;
      font-weight: 500;
      // min-height: 40px;
      display: flex;
      align-items: center;
    }

    p {
      margin-top: 8px;
      margin-bottom: 0px;
      color: rgba($black, 0.6);
      @include fontSize(13px);
    }

    ul {
      margin-left: 12px;

      li {
        color: rgba($black, 0.6);
        @include fontSize(13px);
      }
    }

    $producticons: amount, bonus, hospital, healthcheckups, accident, doc,
      extrapremium, location, treatments, recharge, searched, familys, discount;
    $k: 0;

    @each $icons in $producticons {
      $bacRightP: $k * 50;
      $k: $k + 1;

      .#{$icons} {
        background: url("../../../../public/images/addon-icons.svg") no-repeat -#{$bacRightP}px 0px/650px;
        display: block;
        margin-right: 12px;
        height: 50px;
        min-width: 50px;
        width: 50px;
      }
    }
  }
}
@import "../../SCSS/CommonSCSS/mixins.scss";

.addonQuestion {
  width: 100%;
  margin-bottom: 12px;

  &.borderBottom {
    position: relative;

    &:before {
      border-bottom: 1px solid rgba($black, 0.1);
      @include position(absolute, $bottom: 0px, $left: 24px);
      content: "";
      height: 1px;
      width: calc(100% - 44px);
    }
  }

  .amount {
    color: $secondary;
    @include fontSize(16px);
    font-weight: 500;
  }

  .innerheading {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    color: $primary;
    font-size: 14px;
    position: relative;
    display: inline-block;

    &:before {
      @include position(absolute, $left: 0px, $bottom: -6px);
      width: 100%;
      height: 1px;
      background: $primary;
      content: "";
    }
  }

  &Inner {
    display: flex;
    // align-items: center;

    h6 {
      font-weight: 500;
      // min-height: 40px;
      // display: flex;
      align-items: center;
      word-break: break-word;
      // text-transform: lowercase;
      margin-bottom: 4px;
      &:first-letter {
        text-transform: uppercase;
      }
    }

    p {
      margin-bottom: 0px;
      color: rgba($black, 0.6);
      @include fontSize(13px);
    }

    ul {
      margin-left: 12px;

      li {
        color: rgba($black, 0.6);
        @include fontSize(13px);
      }
    }

    $producticons: amount, bonus, hospital, healthcheckups, accident, doc,
      extrapremium, location, treatments, recharge, searched, familys, discount;
    $k: 0;

    @each $icons in $producticons {
      // $bacRightP: $k * 50;
      $k: $k + 1;

      .#{$icons} {
        background: url("../../../public/images/q-icon.svg")
          no-repeat
          0px
          0px/100%;
        // background: url("../../../public/images/addon-icons.svg")
        //   no-repeat -#{$bacRightP}px
        //   0px/650px;
        display: block;
        margin-right: 12px;
        height: 36px;
        min-width: 36px;
        width: 36px;
      }
    }
  }

  .question-part {
    h6 {
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      // min-height: 40px;
      // display: flex;
      align-items: center;
      // text-transform: lowercase;

      &:first-letter {
        text-transform: uppercase;
      }
    }

    p {
      margin-top: 8px;
      margin-bottom: 0px;
      color: rgba($black, 0.6);
      @include fontSize(13px);
      text-transform: lowercase;

      &:first-letter {
        text-transform: uppercase;
      }
    }

    ul {
      margin-left: 12px;

      li {
        color: rgba($black, 0.6);
        @include fontSize(13px);
      }
    }

    $producticons: amount, bonus, hospital, healthcheckups, accident, doc,
      extrapremium, location, treatments, recharge, searched, familys, discount;
    $k: 0;

    @each $icons in $producticons {
      // $bacRightP: $k * 50;
      $k: $k + 1;

      .#{$icons} {
        background: url("../../../public/images/q-icon.svg")
          no-repeat
          0px
          0px/100%;
        // background: url("../../../public/images/addon-icons.svg")
        //   no-repeat -#{$bacRightP}px
        //   0px/650px;
        display: block;
        margin-right: 12px;
        height: 36px;
        min-width: 36px;
        width: 36px;
      }
    }
  }

  .newList {
    display: flex;
    flex-wrap: wrap;
    row-gap: 6px;
    li {
      width: 50%;
    }
  }
}

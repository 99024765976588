@import "../../../../SCSS/HI/mixins.scss";

.toggles {
  display: inline-block;
  input {
    display: none;
  }

  label {
    @include border-radius(3px);
    cursor: pointer;
    display: block;
    height: 36px;
    overflow: hidden;
    position: relative;
    width: 90px;
    margin-bottom: 0px;
    font-size: 14px;
    text-align: left;

    &:before,
    &:after {
      @include border-radius(3px);
      color: $black;
      display: block;
      padding: null, 0px, null, 0px;
      @include position(absolute, $top: 0px, $left: 0px);
      transition: all 0.5s ease 0s;
    }

    &:before {
      content: "Added";
      right: 0px;
      text-indent: -70px;
      padding: 5px 14px;
      border: 1px solid $black;
    }

    &:after {
      background-color: $black;
      content: "Add";
      top: 10px;
      left: 20px;
      text-indent: 23px;
      line-height: 12px;
      width: 12px;
      height: 12px;
    }
  }

  input {
    &:checked + label {
      &::before {
        background-color: $primary;
        border: 1px solid transparent;
        text-indent: 0px;
        color: $white;
        padding: 5px 10px;
      }

      &:after {
        left: 66px;
        background-color: $white;
      }
    }
  }
}

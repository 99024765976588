@import "../../SCSS/CommonSCSS/mixins.scss";

.switch {
  @include border-radius(4px);
  border: 1px solid $black;
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 32px;
  overflow: hidden;
  button {
    text-transform: capitalize;
    @include fontSize(12px);
    font-weight: 400;
    &.Mui-selected {
      color: $white;
      background-color: #4C7CF8;
      &:hover {
        background-color: #4C7CF8;
      }
    }
    &.Mui-disabled {
      opacity: 0.4!important;
    }
  }
}

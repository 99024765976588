@import "../../SCSS/CommonSCSS/mixins.scss";

.medicalQuestion {
  width: 100%;

  &.borderBottom {
    position: relative;

    &:before {
      border-bottom: 1px solid rgba($black, 0.1);
      @include position(absolute, $bottom: 0px, $left: 24px);
      content: "";
      height: 1px;
      width: 100%;
      @media(max-width:699px){
        left: 0;
        bottom:-5px
      }
    }
  }

  &Inner {
    display: flex;

    h6 {
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      min-height: 30px;
      // display: flex;
      align-items: center;
      text-transform: lowercase;
      &:first-letter {
        text-transform: uppercase;
      }
    }

    p {
      margin-top: 3px;
      margin-bottom: 4px;
      color: rgba($black, 0.6);
      @include fontSize(13px);
      text-transform: lowercase;
      &:first-letter {
        text-transform: uppercase;
      }
    }

    ul {
      margin-left: 12px;

      li {
        color: rgba($black, 0.6);
        @include fontSize(13px);
      }
    }

    $producticons: amount, bonus, hospital, healthcheckups, accident, doc,
      extrapremium, location, treatments, recharge, searched, familys, discount;
    $k: 0;

    @each $icons in $producticons {
      // $bacRightP: $k * 50;
      $k: $k + 1;

      .#{$icons} {
        background: url("../../../public/images/q-icon.svg") no-repeat 0px 0px/100%;
        // background: url("../../../public/images/addon-icons.svg")
        //   no-repeat -#{$bacRightP}px
        //   0px/650px;
        display: block;
        margin-right: 12px;
        height: 36px;
        min-width: 36px;
        width: 36px;
      }
    }

    $covers: preexisting, medicalhistory, healthcheckup, treatment, medicines,
      hospitalized, alcohol, smoking, tobaco, intoxication, group, comorbidity,
      bloodpressure, cholesterol, bloodsugar, spinal, uterus, ulcer, headache,
      hernia, stroketia, thyroid, earnose, cholelithiasis, hemorrhoids,
      prostrate, goodhealth, claimed, insurancepolicy, liverdiseases;
    $k: 0;

    @each $icons in $covers {
      // $bacRightP: $k * 50;
      $k: $k + 1;

      .#{$icons} {
        background: url("../../../public/images/q-icon.svg") no-repeat 0px 0px/100%;
        // background: url("../../../public/images/medical-health-icons.svg")
        //   no-repeat -#{$bacRightP}px
        //   0px/1500px;
        display: block;
        margin-right: 12px;
        height: 36px;
        min-width: 36px;
        width: 36px;
      }
    }

    $producticons: hivaids, cancer, heartdisease, jointpain, kidneydiesase,
      paralysis, lungs, endocrinesystem, insurancedeclined, notmentioned,
      maternity, pregnancy, brainstroke, sle, slippeddisc, deviatednasal,
      mentalillness, handicaped, bloodtest, bpl, unorganizedsector,
      informalsector, hazardousactivity, insulin, eyes, wounds, politician,
      abroad, convicted;
    $k: 0;

    @each $icons in $producticons {
      // $bacRightP: $k * 50;
      $k: $k + 1;

      .#{$icons} {
        background: url("../../../public/images/q-icon.svg") no-repeat 0px 0px/100%;
        // background: url("../../../public/images/medical-term-icons.svg")
        //   no-repeat -#{$bacRightP}px
        //   0px/1450px;
        display: block;
        margin-right: 12px;
        height: 36px;
        min-width: 36px;
        width: 36px;
      }
    }
  }

  &-SubSection {
    padding-left: 62px;
    .checkBox {
      .MuiCheckbox-root {
        padding: 6px;
      }

      svg {
        height: 20px;
      }

      span {
        @include fontSize(15px);
      }
    }
  }
}

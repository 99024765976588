@import "./../../../../../SCSS/CommonSCSS/mixins.scss";

#{$policyConfirm}Wrapper {
  .MuiGrid2-container {
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
  }

  background: $lightbg;
  padding-top: 80px;
  min-height: 100vh;

  h3 {
    @include fontSize(28px);
    text-align: center;
  }

  p {
    @include fontSize(20px);
    text-align: center;
    span {
      font-weight: 700;
    }
  }

  .dwnld_p {
    @include fontSize(16px);
    text-align: center;
    a {
      color: #e6253c;
      position: relative;
      padding-left: 25px;
      &:before {
        @include position(absolute, $left: 0px, $top: 2px);
        content: "";
        background: url("./../../../../../../public/images/download_icon.svg")
          no-repeat 0px /21px;
        height: 14px;
        width: 21px;
      }
    }
  }
}

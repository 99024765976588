// @import "./../../../../../SCSS/Mixins.scss";
@import "./../../SCSS/CommonSCSS/mixins.scss";

.medicalQuestion_m {
    width: 100%;
    margin-bottom: 16px;
    position: relative;
    // right: 10px;

    &:last-child {
        margin-bottom: 0;
    }

    h6 {
        font-weight: 700;
        word-break: break-word;
    }

    hr {
        border: none;
        border-bottom: 1px solid #E0E0E0;
        margin-top: 12px !important;
    }

    .toggleDetailBox {
        .innerQues {
            line-height: 20px !important;
            font-weight: 700;
            font-size: 14px !important;
            color: $darkgrey !important;
        }
    }
    &-SubSection {
        padding-left: 12px;
        .checkBox {
          .MuiCheckbox-root {
            padding: 6px;
          }
    
          svg {
            height: 20px;
          }
    
          span {
            @include fontSize(15px);
          }
        }
      }
}
@import "../../../../SCSS/HI/mixins.scss";

.ctaBtn {
  %primary {
    background: $primary;
    color: $white;
    @include border-radius(6px);
    text-transform: capitalize;
    &:hover {
      background: lighten($primary, 10%);
    }
  }
  %secondary {
    background: $secondary;
    color: $white;
    @include border-radius(6px);
    text-transform: capitalize;
    &:hover {
      background: lighten($secondary, 8%);
    }
  }
  .smallSecondaryBtn {
    @extend %secondary;
    @include fontSize(14px);
    padding: 4px 24px;
  }
  .regularSecondaryBtn {
    @extend %secondary;
    @include fontSize(16px);
    padding: 8px 32px;
  }
  .regularPrimaryBtn {
    @extend %primary;
    @include fontSize(16px);
    padding: 8px 32px;
  }
}

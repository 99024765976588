@import "../../../../SCSS/CommonSCSS/mixins.scss";

.medicalQuestionMobile {
  padding-top: 12px;
  padding-bottom: 12px;
  width: 100%;
  &.borderBottom {
    position: relative;
    &:before {
      border-bottom: 1px solid rgba($black, 0.1);
      @include position(absolute, $bottom: 8px, $left: 0px);
      content: "";
      height: 1px;
      width: 100%;
    }
  }
  .medicalQuestionInner {
    // margin: 4px 0px;
    margin-bottom: 12px;
    display: flex;
    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    h6 {
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      min-height: 34px;
      display: flex;
      align-items: center;
      @include fontSize(13px);
      // text-align: justify;
    }
    p {
      margin-top: 6px;
      margin-bottom: 0px;
      color: rgba($black, 0.6);
      @include fontSize(13px);
    }
    ul {
      margin-left: 12px;
      li {
        color: rgba($black, 0.6);
        @include fontSize(13px);
      }
    }
    $producticons: amount, bonus, hospital, healthcheckups, accident, doc,
      extrapremium, location, treatments, recharge, searched, familys, discount;
    $k: 0;

    @each $icons in $producticons {
      $bacRightP: $k * 30;
      $k: $k + 1;

      .#{$icons} {
        background: url("../../../../../public/images/addon-icons.svg")
          no-repeat -#{$bacRightP}px
          0px/390px;
        display: block;
        margin-right: 4px;
        height: 30px;
        min-width: 30px;
        width: 30px;
      }
    }

    $covers: preexisting, medicalhistory, healthcheckup, treatment, medicines,
      hospitalized, alcohol, smoking, tobaco, intoxication, group, comorbidity,
      bloodpressure, cholesterol, bloodsugar, spinal, uterus, ulcer, headache,
      hernia, stroketia, thyroid, earnose, cholelithiasis, hemorrhoids,
      prostrate, goodhealth, claimed, insurancepolicy, liverdiseases;
    $k: 0;

    @each $icons in $covers {
      $bacRightP: $k * 30;
      $k: $k + 1;

      .#{$icons} {
        background: url("../../../../../public/images/medical-health-icons.svg")
          no-repeat -#{$bacRightP}px
          0px/900px;
        display: block;
        margin-right: 4px;
        height: 30px;
        min-width: 30px;
        width: 30px;
      }
    }

    $producticons: hivaids, cancer, heartdisease, jointpain, kidneydiesase,
      paralysis, lungs, endocrinesystem, insurancedeclined, notmentioned,
      maternity, pregnancy, brainstroke, sle, slippeddisc, deviatednasal,
      mentalillness, handicaped, bloodtest, bpl, unorganizedsector,
      informalsector, hazardousactivity, insulin, eyes, wounds, politician,
      abroad, convicted;
    $k: 0;

    @each $icons in $producticons {
      $bacRightP: $k * 30;
      $k: $k + 1;

      .#{$icons} {
        background: url("../../../../../public/images/medical-term-icons.svg")
          no-repeat -#{$bacRightP}px
          0px/870px;
        display: block;
        margin-right: 4px;
        height: 30px;
        min-width: 30px;
        width: 30px;
      }
    }
    $producticons: army, testpolitive, hopital, masks, cough, tablets, doctor;
    $k: 0;

    @each $icons in $producticons {
      $bacRightP: $k * 30;
      $k: $k + 1;

      .#{$icons} {
        // background: url("../../../../../public/images/medical-term-icons2.svg")
        //   no-repeat -#{$bacRightP}px
        //   0px/600px;
        display: block;
        margin-right: 4px;
        height: 30px;
        min-width: 30px;
        width: 30px;
      }
    }
  }
  .medicalQuestiontoggleSwitch {
    .MuiToggleButtonGroup-root {
      height: 32px;
      border: 1px solid $black;
      button {
        border: none;
        text-transform: capitalize;
        @include fontSize(11px);
        &.Mui-selected {
          background: $primary;
          color: $white;
        }
      }
    }
  }
  .medicalQuestion-SubSection {
    padding-left: 32px;
    // padding-top: 12px;
    .checkBox {
      .MuiCheckbox-root {
        padding: 6px;
      }
      svg {
        height: 20px;
      }
      span {
        @include fontSize(13px);
      }
    }
  }
}

@import "../../../../../../SCSS/CommonSCSS/mixins.scss";

// Proposal Footer
#{$proposal}FooterMobile {
  background: $lightbg;
  // padding-top: 8px;
  &:before {
    background: rgba($black, 0.1);
    @include position(absolute, $top: 0px, $right: 0px);
    content: "";
    height: 1px;
    width: 100%;
  }

  @include position(fixed, $bottom: 0px, $left: 0px);
  height: 52px;
  width: 100%;
  z-index: 9;
  .footerInner {
    min-height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 8px;
  }

  // .backStep {
  //     position: relative;
  //     color: $black;
  //     padding: 4px 0px;
  //     display: inline-flex;
  //     align-items: center;
  //     width: 120px;

  //     &:hover {
  //         svg {
  //             left: -4px;
  //         }
  //     }
  //     svg {
  //         height: 16px;
  //         position: relative;
  //         left: 0px;
  //         transition: all 0.3s ease;
  //     }
  // }
  .ctaBtn {
    flex: 0 0 100%;
    max-width: 72%;
    text-align: center;
    button {
      svg {
        animation: animName 2s linear infinite;
        margin-right: 4px;
        height: 20px;
      }
      @keyframes animName {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
      &.Mui-disabled {
        background: rgba($black, 0.2);
      }
    }
  }
}

@import "../../SCSS/CommonSCSS/mixins.scss";

.ctaBtn {
  %primary {
    background: $primary;
    color: $white;
    @include border-radius(6px);
    text-transform: capitalize;
    box-shadow: none;

    &:hover {
      background: lighten($primary, 10%);
      box-shadow: none;
    }
    &.Mui-disabled {
      background: lighten($black, 80%) !important;
      box-shadow: none;
      cursor: not-allowed;
    }

    .loader {
      transform: translate(-50%, -50%);
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid $white;
      border-right-color: transparent;
      transform-origin: center;
      animation: rotate 1s linear infinite;
    }

    @keyframes rotate {
      from {
        transform: rotate(0);
      }

      to {
        transform: rotate(360deg);
      }
    }
  }

  %secondary {
    background: $secondary;
    color: $white;
    @include border-radius(6px);
    text-transform: capitalize;
    box-shadow: none;

    &:hover {
      background: lighten($secondary, 8%);
      box-shadow: none;
    }
    &.Mui-disabled {
      background: $primary !important;
    }

    .loader {
      transform: translate(-50%, -50%);
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 2px solid $white;
      border-right-color: transparent;
      transform-origin: center;
      animation: rotate 1s linear infinite;
    }

    @keyframes rotate {
      from {
        transform: rotate(0);
      }

      to {
        transform: rotate(360deg);
      }
    }
  }

  .smallSecondaryBtn {
    @extend %secondary;
    @include fontSize(12px);
    padding: 4px 24px;
  }

  .mediumSecondaryBtn {
    @extend %secondary;
    @include fontSize(16px);
    padding: 6px 28px;
  }
  .regularSecondaryBtn {
    @extend %secondary;
    @include fontSize(16px);
    padding: 8px 32px;
  }

  .mediumPrimaryBtn {
    @extend %primary;
    @include fontSize(16px);
    padding: 6px 28px;
  }
  .regularPrimaryBtn {
    @extend %primary;
    @include fontSize(16px);
    padding: 8px 32px;
  }

}

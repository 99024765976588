@import "../../../../../SCSS/HI/mixins.scss";

.addonQuestion {
  width: 100%;

  &.borderBottom {
    position: relative;

    &:before {
      border-bottom: 1px solid rgba($black, 0.1);
      @include position(absolute, $bottom: 0px, $left: 24px);
      content: "";
      height: 1px;
      width: calc(100% - 44px);
    }
  }

  .amount {
    color: $secondary;
    @include fontSize(16px);
    font-weight: 500;
    margin-bottom: 12px;
  }
  .innerheading {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    color: $primary;
    font-size: 14px;
    position: relative;
    display: inline-block;
    &:before {
      @include position(absolute, $left: 0px, $bottom: -6px);
      width: 100%;
      height: 1px;
      background: $primary;
      content: "";
    }
  }

  &Inner {
    display: flex;

    h6 {
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      // min-height: 40px;
      display: flex;
      align-items: center;
    }

    p {
      margin-top: 8px;
      margin-bottom: 0px;
      color: rgba($black, 0.6);
      @include fontSize(13px);
    }

    ul {
      margin-left: 12px;

      li {
        color: rgba($black, 0.6);
        @include fontSize(13px);
      }
    }

    $producticons: amount, bonus, hospital, healthcheckups, accident, doc,
      extrapremium, location, treatments, recharge, searched, familys, discount;
    $k: 0;

    @each $icons in $producticons {
      $bacRightP: $k * 50;
      $k: $k + 1;

      .#{$icons} {
        background: url("../../../../../../public/images/addon-icons.svg")
          no-repeat -#{$bacRightP}px
          0px/650px;
        display: block;
        margin-right: 12px;
        height: 50px;
        min-width: 50px;
        width: 50px;
      }
    }
  }

  .question-part {
    h6 {
      font-family: "Montserrat", sans-serif;
      font-weight: 500;
      // min-height: 40px;
      display: flex;
      align-items: center;
    }

    p {
      margin-top: 8px;
      margin-bottom: 0px;
      color: rgba($black, 0.6);
      @include fontSize(13px);
    }

    ul {
      margin-left: 12px;

      li {
        color: rgba($black, 0.6);
        @include fontSize(13px);
      }
    }

    $producticons: amount, bonus, hospital, healthcheckups, accident, doc,
      extrapremium, location, treatments, recharge, searched, familys, discount;
    $k: 0;

    @each $icons in $producticons {
      $bacRightP: $k * 50;
      $k: $k + 1;

      .#{$icons} {
        background: url("../../../../../../public/images/addon-icons.svg")
          no-repeat -#{$bacRightP}px
          0px/650px;
        display: block;
        margin-right: 12px;
        height: 50px;
        min-width: 50px;
        width: 50px;
      }
    }
  }
}

@import "../../../../../SCSS/CommonSCSS/mixins.scss";

#{$payment}Wrapper {
  .MuiGrid2-container {
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
  }

  background: $lightbg;
  padding-top: 80px;
  min-height: 100vh;

  h3 {
    @include fontSize(28px);
    text-align: center;
  }

  p {
    @include fontSize(18px);
    text-align: center;
    color: lighten($black, 60%);
  }

  #{$payment}Detail_p {
    @include fontSize(18px);
    text-align: center;
    color: $black;
  }

  #{$payment}Policy_box {
    border: 1px solid #e6e6e6;
    @include border-radius(8px);
    padding: 0px;
    align-items: center;
    p {
      color: #161a2e;
      @include fontSize(15px);
      margin-bottom: 0;
      line-height: 20px;
      span {
        display: block;
        font-weight: 600;
      }
    }
  }

  .steps {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    li {
      list-style: none;
      position: relative;
      padding-left: 50px;
      @include fontSize(16px);
      line-height: 24px;
      width: 20%;
      margin-bottom: 20px;

      span {
        @include fontSize(16px);
        line-height: 24px;
        text-align: center;
        color: #24154c;
        margin-right: 20px;
        background: #f4f3fe;
        height: 25px;
        width: 25px;
        display: inline-block;
        border-radius: 60px;
        &.activebg {
          background: #35cb5d !important;
          color: $white;
        }
      }

      &::after {
        @include position(absolute, $left: 0px, $top: 11px);
        width: 32px;
        height: 1px;
        background: #161a2e;
        content: "";
      }
      &:first-child {
        padding-left: 0px;
        &::after {
          display: none;
        }
      }
      &.activeLi {
        &::after {
          background: #35cb5d !important;
        }
      }
    }
  }
}

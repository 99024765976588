@import "../../../../../SCSS/CommonSCSS/mixins.scss";

#{$payment}WrapperMobile {
  min-height: 100vh;
  .topbar {
    padding: 12px;
    position: fixed;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    background: #fff;
    z-index: 999;
    width: 100%;
    .logo {
      height: 30px;
    }
  }
  .innerWrapper {
    padding-top: 80px !important;
    text-align: center;
    padding: 12px 12px 70px;
    .imgsec {
      img {
        height: 100px;
      }
    }
    h3 {
      @include fontSize(20px);
    }
    p {
      @include fontSize(14px);
      color: lighten($black, 60%);
      span {
        font-weight: 700;
      }
    }
    .paymentDetail_p {
      @include fontSize(16px);
      text-align: center;
      color: #000000;
    }
    .policy_detail {
      border: 1px solid #e6e6e6;
      border-radius: 12px;
      .policy_detail_topbar {
        border-bottom: 1px solid #e6e6e6;
      }
      .detail_p {
        @include fontSize(13px);
        color: #000000;
        line-height: 18px;
        span {
          font-weight: 600;
          display: block;
        }
      }
    }
    .steps {
      display: flex;
      flex-wrap: wrap;
      margin-left: 0;
      li {
        width: 50%;
        list-style: none;
        position: relative;
        @include fontSize(14px);
        line-height: 18px;
        text-align: left;
        padding-bottom: 30px;
        span {
          @include fontSize(14px);
          line-height: 18px;
          text-align: center;
          color: #24154c;
          margin-right: 10px;
          background: #f4f3fe;
          height: 20px;
          width: 20px;
          display: inline-block;
          border-radius: 60px;
          &.activebg {
            background: #35cb5d !important;
            color: $white;
          }
        }

        &::after {
          @include position(absolute, $right: 5px, $top: 10px);
          width: 20px;
          height: 1px;
          background: #161a2e;
          content: "";
          @media (max-width: 380px) {
            width: 10px;
            right: 5px;
          }
        }
        &:last-child {
          padding-bottom: 0;
          &::after {
            display: none;
          }
        }
        &.activeLi {
          &::after {
            background: #35cb5d !important;
          }
        }
      }
    }
    .footer {
      @include position(fixed, $left: 0px, $bottom: 0px);
      border-top: 1px solid rgba($black, 0.1);
      background: $white;
      padding-top: 12px;
      height: 60px;
      width: 100%;
    }
  }
}

@import "../../../../../../SCSS/CommonSCSS/mixins.scss";

#{$documents}Wrapper {
  h6 {
    color: $primary;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    svg {
      margin-right: 8px;
    }
  }
  #{$documents}List {
    display: flex;
    li {
      list-style-type: none;
      padding-right: 24px;
      p {
        @include fontSize(16px);
        position: relative;
        padding-left: 28px;
        padding-right: 12px;
        &:before {
          @include position(absolute, $left: 0px, $top: 0px);
          content: "";
          height: 20px;
          width: 20px;
        }
        &:after {
          @include position(absolute, $right: 0px, $top: 0px);
          content: "*";
          color: $secondary;
          height: 6px;
          width: 6px;
        }
        $covers: idDoc, incomeDoc, addressDoc, permanentAddressDoc, ageDoc;
        $k: 0;

        @each $icons in $covers {
          $bacRightP: $k * 20;
          $k: $k + 1;

          &.#{$icons} {
            &:before {
              // background: url("../../../../../../../public/images/documents-icons.svg")
              //   no-repeat -#{$bacRightP}px
              //   0px/100px;
            }
          }
        }
      }
    }
  }
  #{$documents}Notes {
    margin-left: 14px;
    li {
      margin-bottom: 4px;
    }
  }
}
